<template>
  <div class="PostDetail">
    <div class="f-c-b">
      <el-page-header @back="backPrivious" content="帖子详情"></el-page-header>
      <el-button v-show="userId == DetailInfo.postUserId" @click="deletePost" type="primary"><i
          class="el-icon-delete"></i></el-button>
    </div>
    <div class="detail_box">
      <div class="title">{{ DetailInfo.postHead }}</div>
      <div v-html="DetailInfo.postContent"></div>
    </div>
    <div class="comment">
      <div class="public-comment f-c-c">
        <VueEmoji :width="'700'" @input="onInput" :value="commentValue" />
        <el-button class="ml-30" type="primary" @click="publicBtn" round>发表</el-button>
      </div>
      <div class=" comment-length">共 {{ firstLevelCommentsList.length }} 条评论
      </div>
      <div class="comment-box ">
        <CommentItem :DetailInfo="DetailInfo" @showSecondCommontBox="showSecondCommontBox" :list="firstLevelCommentsList"
          :CommentType="'first'" :showCheck="true" :queryType="queryType">
        </CommentItem>
      </div>
      <Pagination v-if="firstLevelCommentsList.length" :total="total" :pageNum.sync="currentPage" :limit.sync="size"
        :layout="layout" @pagination="fetchData" />
      <el-empty v-if="firstLevelCommentsList.length == 0" description="暂无评论！"></el-empty>
    </div>
    <SecondaryCommentsDialog :ShowSecondaryCommentsDialog="ShowSecondaryCommentsDialog" :SecondaryParams="SecondaryParams"
      @closeSecondaryCommentsDialog="closeSecondaryCommentsDialog" :queryType="queryType">
    </SecondaryCommentsDialog>
  </div>
</template>

<script>
import { UrlDecodeIdType } from '@/mixins/UrlDecodeMixin';
import { PaginationRetention1 } from '@/mixins/PaginationRetentionMixin'
import VueEmoji from 'emoji-vue'
export default {
  name: 'PostDetail',
  mixins: [UrlDecodeIdType, PaginationRetention1],
  inject: ['reload'],
  components: {
    VueEmoji,
    CommentItem: () => import("./components/CommentItem.vue"),
    SecondaryCommentsDialog: () => import("./components/SecondaryComments.vue")
  },
  data() {
    return {
      DetailInfo: {},
      userId: this.$getStorage('userId'),
      firstLevelCommentsList: [],//产品交流一级评论列表
      commentValue: '',//评论内容
      ShowSecondaryCommentsDialog: false,//显示二级评论盒子
      SecondaryParams: {},
      IpAddressInfo: {}
    }
  },
  created() {
    switch (this.queryType) {
      case 'IndustryTrends'://行业动态
        this.contentTitle = '行业动态'
        break;
      case 'SupplyAndPurchase'://供应求购
        this.contentTitle = '供应求购';
        break;
      case 'ProductExchange'://产品交流
        this.forumArticleDetails();
        break;
      case 'ShiLiaoZaTan'://识料杂谈
        this.forumChatArticleDetails()
        break;
    }
    this.$CommonRequests.getIpAddress().then((res) => {
      if (res.code == 200) {
        let { ip, province, city, districts } = res.data,
          IpAddressInfo = {
            fcommentLocation: province + '*' + city + '*' + districts,
            sourceIp: ip
          };
        this.IpAddressInfo = IpAddressInfo;
        this.$setStorage('getIpAddress', IpAddressInfo)
      }
    })
  },

  methods: {
    onInput(event) {
      this.commentValue = event.data
    },
    // 发表帖子
    publicBtn() {
      let that = this;
      if (!that.commentValue) {
        return that.$common.message('请输入内容！', 'warning', 1200)
      }
      switch (this.queryType) {
        case 'ProductExchange':
          that.addForumPostComment()
          break;
        case 'ShiLiaoZaTan':
          that.addForumChatPostComment()
          break;

      }
    },
    // 获取详情的请求参数
    getDetailParams() {
      let that = this;
      return {
        postUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        postId: that.queryId
      }
    },
    // 获取移除帖子的请求参数
    getRemoveParams() {
      let that = this;
      return {
        postUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        postId: that.DetailInfo.postId
      }
    },
    // 处理移除帖子的返回结果
    handleRemoveReturnData(res) {
      let that = this;
      if (res.code == 200) {
        that.$common.message(res.msg, 'success')
        that.$common.DelayedBack()
      }
    },
    // 获取一级评论用户发表评论请求参数
    getFirstLevelCommentsParams() {
      let that = this, { postId } = that.DetailInfo, { commentValue } = that,
        { sourceIp, fcommentLocation } = that.IpAddressInfo;
      return {
        fcommentUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        postId,
        fcommentConent: commentValue,
        mobject: '',
        fcommentLocation,
        sourceIp
      }
    },
    // 获取一级评论的参数
    getFirstCommentParams(page, size) {
      let that = this;
      return {
        fcommentUserId: Number(that.$getStorage('userId')),
        postId: that.DetailInfo.postId,
        token: that.$getStorage('token'),
        page,
        size
      }
    },
    // 处理一级评论列表返回数据
    handleFirstCommentReturnData(res) {
      let that = this;
      if (res.code == 200) {
        res.data.records.forEach((item) => {
          item.fcommentLocation = item.fcommentLocation.split('*')[0]
          item.showCommonInput = false
        })
        that.firstLevelCommentsList = res.data.records;
        that.total = res.data.total;
        that.currentPage = res.data.current;
      }
    },

    // 产品交流帖子模块开始
    // 产品交流帖子列表详情接口
    forumArticleDetails() {
      let that = this;
      that.$http.forumArticleDetails(that.getDetailParams()).then((res) => {
        if (res.code == 200) {
          that.DetailInfo = res.data
          that.forumPostCommentList();
        }
      })
    },

    // 产品交流移除帖子
    removeForumArticle() {
      let that = this;
      that.$http.removeForumArticle(that.getRemoveParams()).then(res => {
        that.handleRemoveReturnData(res)
      })
    },
    // 产品交流一级评论模块--帖子评论列表接口
    forumPostCommentList(page = this.page, size = this.size) {
      let that = this;
      that.$http.forumPostCommentList(that.getFirstCommentParams(page, size)).then(res => {
        that.handleFirstCommentReturnData(res)
      })
    },

    // 产品交流一级评论模块--用户发表评论接口
    addForumPostComment() {
      let that = this;
      that.$http.addForumPostComment(that.getFirstLevelCommentsParams()).then(res => {
        if (res.code == 200) {
          that.$common.message(res.msg, 'success')
          that.commentValue = ''
          that.forumPostCommentList()
        }
      })
    },

    // 显示二级评论盒子
    showSecondCommontBox(data) {
      let that = this;
      that.SecondaryParams = data
      that.ShowSecondaryCommentsDialog = true
    },
    // 关闭二级评论盒子
    closeSecondaryCommentsDialog(data) {
      this.ShowSecondaryCommentsDialog = false;
      switch (this.queryType) {
        case 'ProductExchange':
          data ? this.forumPostCommentList() : ''
          break;
        case 'ShiLiaoZaTan':
          data ? this.forumChatPostCommentList() : ''
          break;
      }

    },
    // 产品交流结束


    // 更新列表
    reloadList() {
      let that = this;
      that.firstLevelCommentsList = []
      switch (that.queryType) {
        case 'ProductExchange':
          that.forumPostCommentList()
          break;
        case 'ShiLiaoZaTan':
          that.forumChatPostCommentList()
          break;
      }
    },
    getDate() {
      switch (this.queryType) {
        case 'IndustryTrends'://行业动态
          this.contentTitle = '行业动态'
          break;
        case 'SupplyAndPurchase'://供应求购
          this.contentTitle = '供应求购';
          break;
        case 'ProductExchange'://产品交流
          this.forumPostCommentList();
          break;
        case 'ShiLiaoZaTan'://识料杂谈
          this.forumChatPostCommentList()
          break;
      }
    },

    // 识料杂谈开始
    //  识料杂谈帖子列表详情接口
    forumChatArticleDetails() {
      let that = this;
      that.$http.forumChatArticleDetails(that.getDetailParams()).then((res) => {
        if (res.code == 200) {
          that.DetailInfo = res.data;
          that.forumChatPostCommentList(that.paging, that.pagingSize)
        }
      })
    },
    // 识料杂谈移除帖子
    removeForumChatArticle() {
      let that = this;
      that.$http.removeForumChatArticle(that.getRemoveParams()).then(res => {
        that.handleRemoveReturnData(res)
      })
    },
    // 识料杂谈帖子一级评论列表接口
    forumChatPostCommentList(page = this.page, size = this.size) {
      let that = this;
      that.$http.forumChatPostCommentList(that.getFirstCommentParams(page, size)).then(res => {
        that.handleFirstCommentReturnData(res)
      })
    },
    //识料杂谈帖子模块---一级评论模块 用户发表评论接口
    addForumChatPostComment() {
      let that = this;
      that.$http.addForumChatPostComment(that.getFirstLevelCommentsParams()).then(res => {
        if (res.code == 200) {
          that.$common.message(res.msg, 'success')
          that.commentValue = ''
          that.forumChatPostCommentList()
        }
      })
    },


    // 识料杂谈结束

    // 用户移除帖子接口
    deletePost() {
      let that = this;
      that.$confirm('确定删除？', '提示').then(() => {
        switch (this.queryType) {
          case 'IndustryTrends':
            this.contentTitle = '行业动态'
            break;
          case 'SupplyAndPurchase':
            this.contentTitle = '供应求购';
            break;
          case 'ProductExchange':
            this.removeForumArticle()
            break;
          case 'ShiLiaoZaTan':
            this.removeForumChatArticle()
            break;
        }
      }).catch(() => { })
    },

  },
}

</script>
<style lang='less' scoped>
.PostDetail {
  .detail_box {
    width: 80%;
    margin: auto;

    .title {
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 2px;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  .comment {
    margin-top: 50px;


    .comment-length {
      margin-top: 20px;
    }

    .comment-box {
      padding: 30px;
    }
  }

  .emoji-vue-wraper {
    .emoji-picker-container {
      width: 100%;

      /deep/ .emoji-wysiwyg-editor {
        width: 600px;
        height: auto;
      }

      /deep/ .emoji-picker-icon {
        right: 0
      }
    }
  }

}
</style>
